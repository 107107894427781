import React from 'react';
import { useMutation } from 'react-query';
import { addItemByTargetAction } from '../../../api/actions/globalActions';
import { useDispatch } from 'react-redux';
import { setColumns, setRows } from '../../../api/redux/slices/dataTableSlice';
import { useTranslation } from 'react-i18next';
import { parseColumns } from '../../../Components/Home/Dashboard/DataTable/dataTableHelpers';

export const useBin = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { mutate: handleGetBin } = useMutation(
    () =>
      addItemByTargetAction('files', {
        deleted: true,
        fileTypes: ['DOCUMENT']
      }),
    {
      onSuccess: data => {
        dispatch(setRows(data));
        dispatch(setColumns(parseColumns([], t, 'BIN')));
      }
    }
  );

  return { handleGetBin };
};
