import { useCallback } from 'react';
import {
  deleteItemsByTargetAndIds,
  getFolderDataAction,
  getFolderDataByTargetAction,
  getFolderPreferenceByFolderId
} from '../../../../api/actions/foldersActions';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  defaultBinColumns,
  defaultColumns,
  defaultListAndHtmlColumns,
  fileTypes
} from '../../../../Global/constants';
import { getType, parseValue } from '../../../../Global/globalFunctions';
import { head, isArray, isEmpty } from 'lodash';
import {
  setDataTableViewType,
  setRows,
  setColumns,
  setLoading,
  resetState,
  deleteRow,
  deleteRows
} from '../../../../api/redux/slices/dataTableSlice';
import { openBin, setSelectedTreeNodePermissions } from '../../../../api/redux/slices/treeSlice';
import { Box } from '@mui/material';
import {
  addItemByTargetAction,
  customDeleteAction,
  customUpdateAction,
  deleteItemByTargetAndIdAction,
  getDataByTargetAction
} from '../../../../api/actions/globalActions';
import { addNotification } from '../../../../api/redux/slices/notificationsSlice';
import { useBin } from '../../../../Global/Tree/helpers/binHelpers';

let controller;
export const useDataTable = setFolderStructureOpen => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { selectedTreeNode } = useSelector(state => state.tree);
  const { handleGetBin } = useBin();

  const changeDataTableViewType = viewType => {
    dispatch(setDataTableViewType(viewType));
    if (selectedTreeNode.id.length > 0) {
      if (selectedTreeNode.id === 'bin') {
        dispatch(setLoading(true));
        addItemByTargetAction('files', {
          deleted: true,
          fileTypes: [viewType]
        })
          .then(rows => {
            dispatch(setRows(parseRows(rows, viewType)));
            dispatch(setLoading(false));
          })
          .finally(() => dispatch(setLoading(false)));
      } else {
        dispatch(setLoading(true));
        dispatch(resetState());
        getFolderDataByTargetAction(selectedTreeNode.id, (viewType + 'S').toLocaleLowerCase())
          .then(({ rows, columns }) => {
            dispatch(setRows(parseRows(rows, viewType)));
            dispatch(setColumns(parseColumns(columns, t, viewType)));
            dispatch(setLoading(false));
          })
          .finally(() => dispatch(setLoading(false)));
      }
    }
  };

  const loadFolderData = useCallback(async nodeId => {
    dispatch(resetState());
    if (nodeId === 'root') {
      dispatch(setDataTableViewType(fileTypes.DOCUMENT));
      return;
    }
    if (nodeId === 'bin') {
      handleGetBin();
      dispatch(setDataTableViewType(fileTypes.DOCUMENT));
      dispatch(openBin());
      return;
    }
    const headerFileTypes = await getDataByTargetAction('permissions/fileTypes');
    if (isEmpty(headerFileTypes)) return;
    const currentFileType = await getFolderPreferenceByFolderId(nodeId);
    const usedFileType = headerFileTypes?.includes(currentFileType)
      ? currentFileType
      : headerFileTypes.at(0);
    dispatch(setDataTableViewType(usedFileType));

    setFolderStructureOpen && setFolderStructureOpen(false);

    if (controller) {
      controller.abort();
    }
    controller = new AbortController();

    dispatch(setLoading(true));
    getFolderDataAction(nodeId, usedFileType, controller)
      .then(({ rows, columns, permissions }) => {
        dispatch(setDataTableViewType(usedFileType));
        dispatch(setRows(parseRows(rows, usedFileType)));
        dispatch(setColumns(parseColumns(columns, t, usedFileType)));
        dispatch(setSelectedTreeNodePermissions(permissions));
        dispatch(setLoading(false));
      })
      .finally(() => {
        if (!controller) {
          dispatch(setLoading(false));
        }
      })
      .catch(() => dispatch(setLoading(false)));
  }, []);

  return [loadFolderData, changeDataTableViewType];
};

export const parseColumns = (rawColumns, translate, dataTableViewType) => {
  if (isEmpty(rawColumns)) {
    if (dataTableViewType === fileTypes.TABLE || dataTableViewType === fileTypes.HTML)
      rawColumns = defaultListAndHtmlColumns;
    else if (dataTableViewType === 'BIN') {
      rawColumns = defaultBinColumns;
    } else rawColumns = defaultColumns;
  }

  return rawColumns.map(rawCol => {
    return {
      id: rawCol.id,
      field: rawCol.name,
      headerName: translate(rawCol.name).toUpperCase(),
      minWidth: 150,
      type: getType(rawCol.dataType),
      valueFormatter: params => {
        return parseValue(params, rawCol.dataType);
      },
      flex: 1,
      renderCell: params => (
        <Box
          sx={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center' }}
          draggable='true'
          onDragStart={e => e.dataTransfer.setData('text/plain', JSON.stringify(params.row))}
        >
          {params.formattedValue}
        </Box>
      )
    };
  });
};

export const parseRows = (rawRows, dataTableViewType) => {
  if (dataTableViewType === fileTypes.DOCUMENT) {
    rawRows = rawRows.map(rawRow => {
      return { ...rawRow, ...parseCustomProperties(rawRow) };
    });
  }
  return rawRows;
};

export const parseCustomProperties = row => {
  let object = {};
  if (!isEmpty(row.customProperties)) {
    row.customProperties.forEach(property => {
      object = { ...object, [property.title]: property.value };
    });
  }
  return object;
};

export const handeDelete = (value, rows, folderId) => async dispatch => {
  if (!isArray(value)) {
    if (folderId === 'bin') {
      customDeleteAction('files', [value]).then(() => dispatch(deleteRow(value)));
    } else {
      customUpdateAction('files/trash', [value]).then(() => dispatch(deleteRow(value)));
    }
  } else {
    dispatch(setLoading(true));

    if (folderId === 'bin') {
      customDeleteAction('files', value)
        .then(() => dispatch(deleteRows(value)))
        .finally(() => dispatch(setLoading(false)))
        .catch(({ response }) => {
          if (!isEmpty(response?.data?.message)) {
            let rejectedDocs = rows.filter(row => {
              if (response.data.message.find(id => row.id === id)) {
                return true;
              }
              return false;
            });

            let message = rejectedDocs
              .map(doc => doc.title + ', ')
              .join('')
              .toString();
            dispatch(
              addNotification({ type: 'warning', message: `Files  ${message} cant be deleted` })
            );
          }
        });
    } else {
      customUpdateAction('files/trash', value)
        .then(() => dispatch(deleteRows(value)))
        .finally(() => dispatch(setLoading(false)))
        .catch(({ response }) => {
          if (!isEmpty(response?.data?.message)) {
            let rejectedDocs = rows.filter(row => {
              if (response.data.message.find(id => row.id === id)) {
                return true;
              }
              return false;
            });

            let message = rejectedDocs
              .map(doc => doc.title + ', ')
              .join('')
              .toString();
            dispatch(
              addNotification({ type: 'warning', message: `Files  ${message} cant be deleted` })
            );
          }
        });
    }
  }
};
